var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.dataStorage.stepSettings.loadingIndicator
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { sm: "5" } },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "max-width": "290",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-text-field",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          label: "Date",
                                          placeholder: "Date",
                                          "prepend-inner-icon": "mdi-calendar",
                                          readonly: "",
                                          outlined: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.displayDatePickerIfPossible()
                                          },
                                        },
                                        model: {
                                          value: _vm.dateSelected,
                                          callback: function ($$v) {
                                            _vm.dateSelected = $$v
                                          },
                                          expression: "dateSelected",
                                        },
                                      },
                                      "v-text-field",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        251850790
                      ),
                      model: {
                        value: _vm.datePickerMenu,
                        callback: function ($$v) {
                          _vm.datePickerMenu = $$v
                        },
                        expression: "datePickerMenu",
                      },
                    },
                    [
                      _c("v-date-picker", {
                        attrs: { "allowed-dates": _vm.getAllowDates },
                        on: {
                          change: function ($event) {
                            return _vm.loadTimes(_vm.date)
                          },
                        },
                        model: {
                          value: _vm.date,
                          callback: function ($$v) {
                            _vm.date = $$v
                          },
                          expression: "date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { sm: "5" } },
            [
              _c("v-select", {
                attrs: {
                  "prepend-inner-icon": "mdi-clock-outline",
                  items: _vm.hourRanges,
                  label: "Time",
                  outlined: "",
                },
                on: {
                  change: function ($event) {
                    return _vm.saveDateTime()
                  },
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }