var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("h3", { staticClass: "pl-4" }, [
        _vm._v(_vm._s(_vm.$t("VehicleInformation"))),
      ]),
      _c(
        "v-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.paymentVisible,
              expression: "!paymentVisible",
            },
          ],
        },
        [
          _c(
            "v-col",
            { staticClass: "pr-0", attrs: { sm: "5" } },
            [
              _c(
                "span",
                {
                  staticClass: "u-blue-text font-family-rubik font-weight-bold",
                },
                [_vm._v(" " + _vm._s(_vm.$t("Year")) + " ")]
              ),
              _c(
                "v-layout",
                [
                  _c("v-combobox", {
                    attrs: {
                      items: _vm.years,
                      placeholder: _vm.$t("Year"),
                      outlined: "",
                      rules: [
                        function () {
                          return !!_vm.vehicleYear || _vm.$t("YearRequired")
                        },
                      ],
                    },
                    on: { change: _vm.yearChanged },
                    model: {
                      value: _vm.vehicleYear,
                      callback: function ($$v) {
                        _vm.vehicleYear = $$v
                      },
                      expression: "vehicleYear",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pr-0", attrs: { sm: "5" } },
            [
              _c(
                "span",
                {
                  staticClass: "u-blue-text font-family-rubik font-weight-bold",
                },
                [_vm._v(" " + _vm._s(_vm.$t("MakeWithStar")) + " ")]
              ),
              _c(
                "v-layout",
                [
                  _c("v-select", {
                    attrs: {
                      disabled: _vm.vehicleYear === "",
                      items: _vm.$store.state.appointment.makeList,
                      loading: _vm.isLoading,
                      placeholder: _vm.$t("MakeWithStar"),
                      rules: [
                        function () {
                          return !!_vm.selectedMake || _vm.$t("MakeRequired")
                        },
                      ],
                      outlined: "",
                    },
                    on: { change: _vm.makeChanged },
                    model: {
                      value: _vm.selectedMake,
                      callback: function ($$v) {
                        _vm.selectedMake = $$v
                      },
                      expression: "selectedMake",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pr-0", attrs: { sm: "5" } },
            [
              _c(
                "span",
                {
                  staticClass: "u-blue-text font-family-rubik font-weight-bold",
                },
                [_vm._v(" " + _vm._s(_vm.$t("ModelWithStar")) + " ")]
              ),
              _c(
                "v-layout",
                [
                  _c("v-combobox", {
                    attrs: {
                      disabled: _vm.selectedMake === "",
                      items: _vm.$store.state.appointment.models,
                      placeholder: _vm.$t("ModelWithStar"),
                      rules: [
                        function () {
                          return !!_vm.modelSelected || _vm.$t("ModelRequired")
                        },
                      ],
                      outlined: "",
                      loading: _vm.isLoading,
                    },
                    on: { change: _vm.modelChanged },
                    model: {
                      value: _vm.modelSelected,
                      callback: function ($$v) {
                        _vm.modelSelected = $$v
                      },
                      expression: "modelSelected",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pr-0", attrs: { sm: "5" } },
            [
              _c(
                "span",
                {
                  staticClass: "u-blue-text font-family-rubik font-weight-bold",
                },
                [_vm._v(" " + _vm._s(_vm.$t("LicenseNumber")) + " ")]
              ),
              _c(
                "v-layout",
                [
                  _c("v-text-field", {
                    attrs: {
                      placeholder: _vm.$t("LicenseNumber"),
                      outlined: "",
                    },
                    on: { change: _vm.changeLicense },
                    model: {
                      value: _vm.licenseNumber,
                      callback: function ($$v) {
                        _vm.licenseNumber = $$v
                      },
                      expression: "licenseNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }