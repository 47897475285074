var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "image-input",
      style: {
        "background-image": "url(" + _vm.imageData + ")",
        width: "150px",
        height: "150px",
      },
      on: {
        click: function ($event) {
          return _vm.$refs.file.click()
        },
      },
    },
    [
      _vm.imageData
        ? _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      return [
                        _c(
                          "i",
                          _vm._g(
                            {
                              staticClass:
                                "mdi mdi-close container-image-style",
                              attrs: { "aria-hidden": "true" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.removeImage.apply(null, arguments)
                                },
                              },
                            },
                            on
                          )
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3978893112
              ),
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("RemoveImage")))])]
          )
        : _vm._e(),
      !_vm.imageData
        ? _c("span", { staticClass: "placeholder" }, [
            _vm._v(" " + _vm._s(_vm.$t("ChooseAnImage")) + " "),
          ])
        : _vm._e(),
      _c("input", {
        ref: "file",
        staticClass: "file-input",
        attrs: { type: "file", id: _vm.imagePos },
        on: { change: _vm.previewImage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }