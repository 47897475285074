var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("h3", { staticClass: "pl-4" }, [_vm._v(_vm._s(_vm.$t("Address")))]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pr-0", attrs: { sm: "5" } },
            [
              _c(
                "span",
                {
                  staticClass: "u-blue-text font-family-rubik font-weight-bold",
                },
                [_vm._v(" " + _vm._s(_vm.$t("PickUpLocation")) + " ")]
              ),
              _c("location-auto-complete", {
                attrs: { isDropOff: false, readOnly: _vm.finalStep },
              }),
              (_vm.auctionList.length > 0 && !_vm.finalStep) ||
              _vm.ifNearbyLocationAddress
                ? _c("p", { staticClass: "mb-1 red--text text-center" }, [
                    _vm._v(_vm._s(_vm.$t("ORCapital"))),
                  ])
                : _vm._e(),
              (_vm.auctionList.length > 0 && !_vm.finalStep) ||
              _vm.ifNearbyLocationAddress
                ? _c(
                    "span",
                    { staticClass: "u-blue-text font-family-rubik red--text" },
                    [_vm._v(" " + _vm._s(_vm.$t("SelectANearbyAuction")) + " ")]
                  )
                : _vm._e(),
              (_vm.auctionList.length > 0 && !_vm.finalStep) ||
              this.$cookies.isKey("nearbylocationaddress")
                ? _c("v-autocomplete", {
                    attrs: {
                      items: _vm.auctionList,
                      autocomplete: "new-user-street-address",
                      "item-text": "name",
                      "item-value": "address",
                      clearable: "",
                      outlined: "",
                      placeholder: "Address",
                    },
                    on: { change: _vm.changeNearLocation },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function (data) {
                            return [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", {
                                    domProps: {
                                      textContent: _vm._s(data.item.name),
                                    },
                                  }),
                                  _c("v-list-item-subtitle", {
                                    domProps: {
                                      textContent: _vm._s(data.item.address),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1199556490
                    ),
                    model: {
                      value: _vm.nearLocationSelected,
                      callback: function ($$v) {
                        _vm.nearLocationSelected = $$v
                      },
                      expression: "nearLocationSelected",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "mt-10 ml-8", attrs: { sm: "5" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "d-flex justify-content-space-between",
                  staticStyle: { "background-color": "#12945f" },
                },
                [
                  _c("v-col", { staticClass: "pr-0", attrs: { sm: "5" } }, [
                    _c(
                      "span",
                      {
                        staticClass: "primary-font-style font-weight-bold",
                        staticStyle: { color: "white" },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("TotalCapital")))]
                    ),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "text-right", attrs: { cols: "6" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "primary-font-style font-weight-bold text-right",
                          staticStyle: { color: "white" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("currency")(_vm.estimatedCharged)) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("v-row"),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pr-0", attrs: { sm: "5" } },
            [
              _c(
                "span",
                {
                  staticClass: "u-blue-text font-family-rubik font-weight-bold",
                },
                [_vm._v(" " + _vm._s(_vm.$t("DropOffLocation")) + " ")]
              ),
              _c("location-auto-complete", {
                attrs: { isDropOff: true, readOnly: _vm.finalStep },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { sm: "5" } },
            [
              _c(
                "span",
                {
                  staticClass: "u-blue-text font-family-rubik font-weight-bold",
                  attrs: { outlined: "" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("AddNoteForDriver")) + " ")]
              ),
              _c(
                "v-layout",
                [
                  _c("v-textarea", {
                    attrs: { outlined: "" },
                    on: {
                      change: function ($event) {
                        return _vm.changeNoteEntered()
                      },
                    },
                    model: {
                      value: _vm.note,
                      callback: function ($$v) {
                        _vm.note = $$v
                      },
                      expression: "note",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-alert",
                {
                  attrs: { text: "", type: "success" },
                  model: {
                    value: _vm.alert,
                    callback: function ($$v) {
                      _vm.alert = $$v
                    },
                    expression: "alert",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("NoteForDriverSaved")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("h3", { staticClass: "pl-4" }, [_vm._v(_vm._s(_vm.$t("Schedule")))]),
      _c("towing-appt-date-time-address"),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("image-uploader-preview-towing", {
                        attrs: { imagePos: 0 },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("image-uploader-preview-towing", {
                        attrs: { imagePos: 1 },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("image-uploader-preview-towing", {
                        attrs: { imagePos: 2 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-col"),
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c(
            "v-col",
            { staticClass: "footerNavegable" },
            [
              _c("v-divider"),
              _c(
                "v-btn",
                {
                  staticClass:
                    "white--text u-background-primary text--white mt-3\n           theme--dark",
                  on: { click: _vm.nextPaymentSection },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Next")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }